import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, UnderlineLink } from '~styles/global'
import Image from '~components/Image'
import SlideDown from 'react-slidedown'
import { PlusCircle, Rarr } from './Svg'
import SanityLink from './SanityLink'
import Button from '~components/Button'

const MenuProduct = ({ product, className }) => {
	const [open, setOpen] = useState(false)

	return(
		<Product className={className}>
			<Toggle>
				<Open 
					onClick={() => setOpen(true)}
					css={css`
						pointer-events: ${open ? 'none' : 'all'};
						cursor: ${open ? 'auto' : 'pointer'};
					`}
				>
					<div className='h3'>
						<ProductLink 
							link={{
								product, 
								linkType: 'internal', 
								document: {
									_type: 'variant',
									content: product?.content?.variants[0].content
								}
							}}
							css={css`
								pointer-events: ${open ? 'all' : 'none'};
								cursor: ${open ? 'pointer' : 'auto'};
								&:hover {
									svg{
										transform: translateX(2px);
									}
								}
							`}
						>
							<span>
								{product?.title} 
								{product?.content?.variants[0].content?.dimensions && ` — ${product?.content?.variants[0].content?.dimensions}`} 
								{product?.content?.variants[0].content?.shopify?.price && ` — ${product?.content?.variants[0].content?.shopify?.price}`}
							</span>
							<RightArrow css={css`
								opacity: ${open ? '1' : '0'};
							`}/>
						</ProductLink>
					</div>
				</Open>
				<Close 
					onClick={()=> setOpen(false)}
					css={css`
							opacity: ${open ? '1' : '0'};
							pointer-events: ${open ? 'all' : 'none'};
							cursor: ${open ? 'pointer' : 'auto'};
					`}>
					<Cross/>
				</Close>
			</Toggle>
			<SlideDown 
				css={css`
          transition: 0.4s ease-in-out;
      `}>	
				{open && 
					<ImageContainer>
						<ProductLink 
							link={{
								product, 
								linkType: 'internal', 
								document: {
									_type: 'variant',
									content: product?.content?.variants[0].content
								}
							}}>
							<ProductImage image={product?.content?.variants[0].content?.thumb} aspectRatio={1}/>
						</ProductLink>
					</ImageContainer>
				}
			</SlideDown>
		</Product>
	)
}

const MenuProductMobile = ({ className, product }) => {
	return (
		<ProductMobile 
			className={className} 
			link={{
				product, 
				linkType: 'internal', 
				document: {
					_type: 'variant',
					content: product?.content?.variants[0].content
				}
			}}>
			<h3>
				{product?.title} 
				{product?.content?.variants[0].content?.dimensions && ` — ${product?.content?.variants[0].content?.dimensions}`} 
				{product?.content?.variants[0].content?.shopify?.price && ` — ${product?.content?.variants[0].content?.shopify?.price}`}
			</h3>
			<ProductImage image={product?.content?.variants[0].content?.thumb} aspectRatio={1}/>
		</ProductMobile>	
	)
}

const MobileCategory = ({className, item }) => {
	const [open, setOpen] = useState(false)
	return (
		<CategoryMobile className={className}>
			<Name onClick={()=> setOpen(!open)}>
				{item?.category?.title}
				{item?.products.length > 0 ? 
					<Number dangerouslySetInnerHTML={{__html: `&#931${item?.products.length + 1}`}}/> 
					:
					<Number>
						⓪
					</Number>
				}
			</Name>
			<SlideDown css={css`transition: 0.4s ease-in-out;`}>	
				{open &&
				<>
					<Products>
						{item?.products.map(product => (
							<MenuProductMobile key={product.title} product={product}/>
						))}
					</Products>
					<ViewAll>
						<CategoryLinkMobile 
							className='h3'
							link={{
								linkType: 'internal',
								document: {
									_type: 'productCategory',
									slug: item?.category?.slug
								}
							}}>See all</CategoryLinkMobile>
					</ViewAll>
				</>
				}
			</SlideDown>
		</CategoryMobile>
	)
}

const MenuStore = ({ className, products }) => {
	return (
		<Wrap className={className}>
			{products.map(item => (
				<>
					<Category key={item?.category?.title}>
						<Name>
							<CategoryLink className='h1' link={{
								linkType: 'internal',
								document: {
									_type: 'productCategory',
									slug: item?.category?.slug
								}
							}}>
								{item?.category?.title}
								{item?.products.length > 0 ? 
									<Number dangerouslySetInnerHTML={{__html: `&#931${item?.products.length + 1}`}}/> 
									:
									<Number>
										⓪
									</Number>
								}
							</CategoryLink>
						</Name>
						<Products>
							{item?.products.map(product => (
								<MenuProduct key={product.title} product={product}/>
							))}
						</Products>
					</Category>
					<MobileCategory item={item}/>
				</>
			))}
		</Wrap>
	)
}
const Wrap = styled.div`
`
const Category = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	border-bottom: 1px solid var(--black);
	&:last-of-type{
		border-bottom: none;
	}
	${mobile}{
		display: none;
	}
`
const CategoryMobile = styled.div`
	display: none;
	grid-template-columns: 1fr;
	border-bottom: none;
	${mobile}{
		display: grid;
	}
`
const ViewAll = styled.div`
	padding: var(--s) var(--m);
	border-bottom: 1px solid var(--black);
`
const CategoryLinkMobile = styled(Button)`
	align-items: center;
`
const Name = styled.h1`
	padding: var(--m);
	padding-left: 40px;
	border-right: 1px solid var(--black);
	${mobile}{
		border-right: none;
		border-bottom: 1px solid var(--black);
		padding-left: var(--m);
	}
`
const CategoryLink = styled(UnderlineLink)`
	&:after{
		bottom: -0.1em;
	}
`
const Number = styled.span`
	display: inline-block;
	margin-left: var(--xs);
`
const Products = styled.div`
`
const Product = styled.div`
	border-bottom: 1px solid var(--black);
	:last-of-type {
		border-bottom: none;
	}
`
const ProductMobile = styled(SanityLink)`
	display: grid;
	grid-template-columns: 1fr max-content;
	padding: var(--m);
	grid-column-gap: var(--m);
	border-bottom: 1px solid var(--black);
`
const Toggle = styled.div`
	display: grid;
	grid-template-columns: 1fr max-content;
  text-align: left;
`
const Open = styled.div`
  text-align: left;
	grid-column: 1/3;
	grid-row: 1;
	padding: var(--s);
`
const ProductLink = styled(SanityLink)`
	transition: transform 0.3s;
`
const ImageContainer = styled.div`
	display: inline-block;
`
const Close = styled.button`
	justify-self: end;
	transition: opacity 0.3s;
	grid-column: 2/3;
	grid-row: 1;
	padding: var(--s);
`
const Cross = styled(PlusCircle)`
	transform: rotate(45deg);
	width: 13px;
	height: 13px;
	display: block;	
`
const RightArrow = styled(Rarr)`
	width: 13px; 
	height: 13px; 
	margin-left: 10px;
	transition: transform 0.3s, opacity 0.3s;;
	margin-top: -3px;
	transform: translateX(0px);
	position: relative;
	top: 1px;
	${mobile}{
		margin-left: 5px;
		width: 12px;
		height: 12px;
	}
`
const ProductImage = styled(Image)`
	padding: 0 var(--s) var(--s) var(--s);
	width: 200px;
	${mobile}{
		width: 90px;
		margin-top: 0;
		justify-self: end;
		padding: 0;
	}
`
MenuStore.propTypes = {
	className: PropTypes.string,
	products: PropTypes.array,
}
MenuProduct.propTypes = {
	className: PropTypes.string,
	product: PropTypes.object,
}
MenuProductMobile.propTypes = {
	className: PropTypes.string,
	product: PropTypes.object,
}
MobileCategory.propTypes ={
	className: PropTypes.string,
	item: PropTypes.array,
}
export default MenuStore